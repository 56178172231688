/* eslint-disable */
// prettier-ignore

import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    
    {
      path: '/signup',
      name: 'signup',
      component: () => import('@/views/pages/Register.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/verify-login',
      name: 'verify-login',
      component: () => import('@/views/pages/VerifyLogin.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/verify-login/:id',
      name: 'verify-login',
      component: () => import('@/views/pages/VerifyLogin.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: () => import('@/views/pages/ResetPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import('@/views/pages/ForgotPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/pages/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    
    {
      path: '/confidence',
      name: 'confidence',
      component: () => import('@/views/pages/Confidence.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/confidenceBinar',
      name: 'confidence',
      component: () => import('@/views/pages/ConfidenceBinar.vue'),
      meta: {
        layout: 'full',
      },
    },
    
        {
      path: '/',
      name: 'main',
      component: () => import('@/views/apps/main/Main.vue'),
          },
        {
      path: '/payresult/:id',
      name: 'payresult',
      component: () => import('@/views/apps/payresult/Payresult.vue'),
          },
        {
      path: '/payment/sbprocessing/:id',
      name: 'sbprocessing',
      component: () => import('@/views/apps/sbprocessing/Sbprocessing.vue'),
          },
        {
      path: '/payment/tfprocessing/:id',
      name: 'tfprocessing',
      component: () => import('@/views/apps/tfprocessing/Tfprocessing.vue'),
          },
        {
      path: '/payment/sbpayok/:id',
      name: 'sbpayok',
      component: () => import('@/views/apps/sbpayok/Sbpayok.vue'),
          },
        {
      path: '/payment/sbpayerror/:id',
      name: 'sbpayerror',
      component: () => import('@/views/apps/sbpayerror/Sbpayerror.vue'),
          },
        {
      path: '/page/:stype/:id',
      name: 'page',
      component: () => import('@/views/apps/page/Page.vue'),
          },
        {
      path: '/tinkoff-installment/:id',
      name: 'tinkoff-installment',
      component: () => import('@/views/apps/tinkoff-installment/TinkoffInstallment.vue'),
          },
        {
      path: '/personal-invitations/list',
      name: 'personal-invitations',
      component: () => import('@/views/apps/personal-invitations/PersonalInvitations.vue'),
            meta: {"pageTitle":"\u041b\u0438\u0447\u043d\u044b\u0435 \u043f\u0440\u0438\u0433\u043b\u0430\u0448\u0435\u043d\u0438\u044f"},
          },
        {
      path: '/personal-invitations/invite',
      name: 'invite',
      component: () => import('@/views/apps/invite/Invite.vue'),
            meta: {"pageTitle":"\u041f\u0440\u0438\u0433\u043b\u0430\u0441\u0438\u0442\u044c \u043d\u043e\u0432\u0438\u0447\u043a\u0430"},
          },
        {
      path: '/shop',
      name: 'shop',
      component: () => import('@/views/apps/shop/Shop.vue'),
            meta: {"contentRenderer":"sidebar-left-detached","contentClass":"ecommerce-application","pageTitle":"\u041c\u0430\u0433\u0430\u0437\u0438\u043d","breadcrumb":[{"text":"\u0421\u043f\u0438\u0441\u043e\u043a \u0442\u043e\u0432\u0430\u0440\u043e\u0432","active":true}]},
          },
        {
      path: '/shop/:id/view',
      name: 'shop-details',
      component: () => import('@/views/apps/shop-details/ShopDetails.vue'),
            meta: {"contentClass":"ecommerce-application","pageTitle":"\u041a\u0430\u0440\u0442\u043e\u0447\u043a\u0430 \u0442\u043e\u0432\u0430\u0440\u0430","breadcrumb":[{"text":"\u041c\u0430\u0433\u0430\u0437\u0438\u043d","active":false,"to":"\/shop"},{"text":"\u041a\u0430\u0440\u0442\u043e\u0447\u043a\u0430 \u0442\u043e\u0432\u0430\u0440\u0430","active":true}]},
          },
        {
      path: '/shop/checkout',
      name: 'shop-checkout',
      component: () => import('@/views/apps/shop-checkout/ShopCheckout.vue'),
            meta: {"contentClass":"ecommerce-application","pageTitle":"\u041a\u043e\u0440\u0437\u0438\u043d\u0430","breadcrumb":[{"text":"\u041c\u0430\u0433\u0430\u0437\u0438\u043d","active":false,"to":"\/shop"},{"text":"\u041a\u043e\u0440\u0437\u0438\u043d\u0430","active":true}]},
          },
        {
      path: '/shop/wishlist',
      name: 'shop-wishlist',
      component: () => import('@/views/apps/shop-wishlist/ShopWishlist.vue'),
            meta: {"contentClass":"ecommerce-application","pageTitle":"\u0418\u0437\u0431\u0440\u0430\u043d\u043d\u043e\u0435","breadcrumb":[{"text":"\u041c\u0430\u0433\u0430\u0437\u0438\u043d","active":false,"to":"\/shop"},{"text":"\u0418\u0437\u0431\u0440\u0430\u043d\u043d\u043e\u0435","active":true}]},
          },
        {
      path: '/news',
      name: 'news',
      component: () => import('@/views/apps/news/News.vue'),
            meta: {"pageTitle":"\u041d\u043e\u0432\u043e\u0441\u0442\u0438"},
          },
        {
      path: '/news/:id/view',
      name: 'news-detail',
      component: () => import('@/views/apps/news-detail/NewsDetail.vue'),
            meta: {"pageTitle":"\u0414\u0435\u0442\u0430\u043b\u044c\u043d\u0430\u044f \u0441\u0442\u0440\u0430\u043d\u0438\u0446\u0430","breadcrumb":[{"text":"\u041d\u043e\u0432\u043e\u0441\u0442\u0438","active":false,"to":"\/news"},{"text":"\u0414\u0435\u0442\u0430\u043b\u044c\u043d\u0430\u044f \u0441\u0442\u0440\u0430\u043d\u0438\u0446\u0430","active":true}]},
          },
        {
      path: '/orders',
      name: 'orders',
      component: () => import('@/views/apps/orders/Orders.vue'),
            meta: {"pageTitle":"\u041c\u043e\u0438 \u043f\u043e\u043a\u0443\u043f\u043a\u0438"},
          },
        {
      path: '/tree-structure',
      name: 'tree-structure',
      component: () => import('@/views/apps/tree-structure/TreeStructure.vue'),
            meta: {"pageTitle":"\u0421\u0442\u0440\u0443\u043a\u0442\u0443\u0440\u0430 - \u0414\u0435\u0440\u0435\u0432\u043e"},
          },
        {
      path: '/tree-structure/:id',
      name: 'tree-structure-id',
      component: () => import('@/views/apps/tree-structure/TreeStructure.vue'),
            meta: {"pageTitle":"\u0421\u0442\u0440\u0443\u043a\u0442\u0443\u0440\u0430 - \u0414\u0435\u0440\u0435\u0432\u043e"},
          },
        {
      path: '/contracts',
      name: 'contracts',
      component: () => import('@/views/apps/contracts/Contracts.vue'),
            meta: {"pageTitle":"\u041a\u043e\u043d\u0442\u0440\u0430\u043a\u0442\u044b \u0437\u0430 \u043d\u0435\u0434\u0435\u043b\u044e"},
          },
        {
      path: '/info/about',
      name: 'about',
      component: () => import('@/views/apps/about/About.vue'),
            meta: {"pageTitle":"\u041e \u043d\u0430\u0441"},
          },
        {
      path: '/info/how-to-by',
      name: 'how-to-by',
      component: () => import('@/views/apps/how-to-by/HowToBy.vue'),
            meta: {"pageTitle":"\u041f\u0440\u0430\u0432\u0438\u043b\u0430 \u043f\u043e\u043a\u0443\u043f\u043a\u0438 "},
          },
        {
      path: '/how-use-refer',
      name: 'how-use-refer',
      component: () => import('@/views/apps/how-use-refer/HowUseRefer.vue'),
            meta: {"pageTitle":"\u041a\u0430\u043a \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u044c\u0441\u044f \u0440\u0435\u0444\u0435\u0440\u0430\u043b\u044c\u043d\u043e\u0439 \u0441\u0441\u044b\u043b\u043a\u043e\u0439"},
          },
        {
      path: '/business-tools/academy',
      name: 'academy',
      component: () => import('@/views/apps/academy/Academy.vue'),
            meta: {"pageTitle":"\u0410\u043a\u0430\u0434\u0435\u043c\u0438\u044f"},
          },
        {
      path: '/business-tools/media-bank',
      name: 'media-bank',
      component: () => import('@/views/apps/media-bank/MediaBank.vue'),
            meta: {"pageTitle":"\u041c\u0435\u0434\u0438\u0430 \u0431\u0430\u043d\u043a"},
          },
        {
      path: '/courses',
      name: 'courses',
      component: () => import('@/views/apps/courses/Courses.vue'),
            meta: {"pageTitle":"\u0421\u043f\u0438\u0441\u043e\u043a \u043a\u0443\u0440\u0441\u043e\u0432"},
          },
        {
      path: '/courses/:id/view',
      name: 'courses-preview',
      component: () => import('@/views/apps/courses-preview/CoursesPreview.vue'),
            meta: {"pageTitle":"\u041a\u0443\u0440\u0441","breadcrumb":[{"text":"\u0421\u043f\u0438\u0441\u043e\u043a \u043a\u0443\u0440\u0441\u043e\u0432","active":false,"to":"\/courses"}]},
          },
        {
      path: '/finance',
      name: 'finance',
      component: () => import('@/views/apps/finance/Finance.vue'),
            meta: {"pageTitle":"\u0424\u0438\u043d\u0430\u043d\u0441\u044b"},
          },
        {
      path: '/info/docs',
      name: 'docs',
      component: () => import('@/views/apps/docs/Docs.vue'),
            meta: {"pageTitle":"\u0414\u043e\u043a\u0443\u043c\u0435\u043d\u0442\u044b"},
          },
        {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/apps/profile/Profile.vue'),
            meta: {"pageTitle":"\u041f\u0440\u043e\u0444\u0438\u043b\u044c"},
          },
        {
      path: '/(\\d+)',
      name: 'refsignup',
      component: () => import('@/views/pages/Register.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/(\[a-zA-Z]{1,}[0-9]{1,}\)',
      name: 'refsignup',
      component: () => import('@/views/pages/Register.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

if (localStorage.getItem('userData')) {
  const userData = JSON.parse(localStorage.getItem('userData'))

  
  
}

router.beforeEach(async (to, from, next) => {
  if (!store.getters['app/isLoggedIn'] && localStorage.getItem('accessToken') && localStorage.getItem('userData')) {
    store.commit('app/SET_USER', JSON.parse(localStorage.getItem('userData')))
    store.commit('app/SET_LOGGED_IN', true)
  }

  if (!localStorage.getItem('accessToken') && to.matched[0] && to.matched[0].path !== '/login') {
    if (to.matched[0].path === '/signup') {
      next()
    } else if (to.matched[0].path === '/verify-login/:id') {
      next()
    }
    else if (to.matched[0].path === '/verify-login') {
      next()
    }
    else if (to.matched[0].path === '/(\\d+)') {
      next()
    }
    else if (to.matched[0].path === '/(\[a-zA-Z]{1,}[0-9]{1,}\)') {
      next()
    }
    else if (to.matched[0].path === '/reset-password') {
      next()
    } else if (to.matched[0].path === '/confidence') {
      next()
    }
    else if (to.matched[0].path === '/confidenceBinar') {
      next()
    }
    
    else if (to.matched[0].path === '/forgot-password') {
      next()
    } else if (to.matched[0].path === '/report-form/:id') {
      next()
    } else if (to.matched[0].path === '/report-form/:id/:channel') {
      next()
    } else {
      next({
        path: '/login',
      })
    }
  }

  if (localStorage.getItem('accessToken') && to.matched[0] && to.matched[0].path === '/login') {
    next({
      path: '/',
    })
  }

  next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
